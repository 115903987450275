import React from 'react';
import Header from '../Components/Header';
import HeroSection from '../Components/HeroSection';
import AboutSection from '../Components/AboutSection';
import ServicesSection from '../Components/ServicesSection';
import ProcessSection from '../Components/ProcessSection';
import PricingSection from '../Components/PricingSection';
import BlogSection from '../Components/BlogSection';
import TeamSection from '../Components/TeamSection';
import ContactSection from '../Components/ContactSection';
import Footer from '../Components/Footer';
import WhyChooseUsSection from '../Components/WhyChooseUsSection';
import VideoSection from '../Components/VideoSection';
import ContactForm from '../Components/ContactForm';
import GetInTouchBanner from '../Components/GetInTouchBanner';
import BrandLogos from '../Components/BrandLogos';
import ServiceCard from '../Components/ServiceCard';
import SpecialCollection from '../Components/SpecialCollection';
import AppPromo from '../Components/AppPromo';
import Latest from '../Components/Latest';

const Home = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <AboutSection />
      <ServicesSection />
      <SpecialCollection />
      <AppPromo />
      <ContactSection />
      <Footer />
    </>
  );
};

export default Home;
