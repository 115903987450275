import React from 'react';
import AppStore from '../assets/app-store.png';
import Playstore from '../assets/game.png';
import { motion } from 'framer-motion';

const AppPromo = () => (
  <motion.section
    className="bg-yellow-400 text-gray-800 py-16 md:py-24 relative overflow-hidden"
    id='app'
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8 }}
  >
    {/* Optional background overlay */}
    <div className="absolute inset-0 bg-gradient-to-r from-yellow-300 to-yellow-500 opacity-50"></div>

    <div className="container mx-auto px-4 md:px-8 max-w-5xl relative z-10">
      <div className="text-center mb-8 md:mb-12">
        <motion.h2
          className="text-2xl md:text-4xl font-semibold mb-4 md:mb-6 font-Poppins capitalize"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          Let's wash with U-Drive today!
        </motion.h2>
        <motion.p
          className="text-base md:text-xl mb-8 md:mb-10 max-w-md font-Poppins lg:text-lg md:max-w-2xl mx-auto"
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }}
        >
          Get the app to experience the world of premium car washing services. Enjoy fast and convenient bookings to keep your car sparkling clean!
        </motion.p>
      </div>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 space-y-4 md:space-y-0">
        <motion.button
          className="bg-white text-black px-4 py-4 md:px-6 md:py-3 rounded-full flex items-center hover:bg-white transition duration-300 shadow-lg w-auto relative"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <img src={AppStore} alt="Apple logo" className="mr-2 md:mr-4 w-8 md:w-10 h-8 md:h-10 object-contain" />
          <div className="text-left">
            <p className="text-xs font-Poppins md:text-sm">Download on the</p>
            <p className="text-sm md:text-xl font-semibold font-Poppins">App Store</p>
          </div>
          {/* Optional icon effect */}
          <motion.div
            className="absolute -inset-1 bg-green-100 opacity-30 rounded-full"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          />
        </motion.button>
        <motion.button
          className="bg-white text-black px-4 py-4 md:px-6 md:py-3 rounded-full flex items-center hover:bg-white transition duration-300 shadow-lg w-auto relative"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <img src={Playstore} alt="Google Play logo" className="mr-2 md:mr-4 w-8 md:w-10 h-8 md:h-10 object-contain" />
          <div className="text-left">
            <p className="text-xs md:text-sm font-Poppins">Get it on</p>
            <p className="text-sm md:text-xl font-semibold font-Poppins">Google Play</p>
          </div>
          {/* Optional icon effect */}
          <motion.div
            className="absolute -inset-1 bg-green-100 opacity-30 rounded-full"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          />
        </motion.button>
      </div>
    </div>
  </motion.section>
);

export default AppPromo;
