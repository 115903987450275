import React from 'react';
import { motion } from 'framer-motion';

const MissionVisionSection = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <section className="bg-gradient-to-br from-neutral-50 via-neutral-50 to-neutral-100 text-white py-12 sm:py-16 lg:py-20 overflow-hidden font-Poppins">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className="text-2xl sm:text-3xl text-green-600 lg:text-4xl font-bold text-center mb-8 sm:mb-10 lg:mb-12 capitalize font-Poppins"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Our Mission & Vision
        </motion.h2>

        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8 lg:gap-10"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div 
            className="bg-white text-gray-800 rounded-lg p-6 sm:p-8 shadow-lg transition-all duration-300 hover:bg-gray-100 hover:shadow-xl"
            variants={itemVariants}
          >
            <div className="text-center mb-4">
              <svg className="w-10 h-10 mx-auto text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v9.3c0 .34-.074.68-.212.977l-2.7 5.4a1 1 0 01-1.788-.715l2.16-4.32A2.007 2.007 0 0012 12.5V3m0 0a2 2 0 10-2 2m2-2a2 2 0 012 2"></path>
              </svg>
            </div>
            <h3 className="text-xl sm:text-2xl font-bold mb-4">Our Mission</h3>
            <p className="text-base sm:text-lg leading-relaxed font-Poppins">
              At U-Drive, our mission is to deliver exceptional car care that exceeds expectations. We are committed to innovation, quality, and sustainability to ensure your vehicle receives the best service with a minimal environmental footprint.
            </p>
          </motion.div>

          <motion.div 
            className="bg-white text-gray-800 rounded-lg p-6 sm:p-8 shadow-lg transition-all duration-300 hover:bg-gray-100 hover:shadow-xl"
            variants={itemVariants}
          >
            <div className="text-center mb-4">
              <svg className="w-10 h-10 mx-auto text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 21v-6.3a2.007 2.007 0 00-1.672-1.92L8.16 12.46a1 1 0 01-1.788.715l2.16-4.32A2 2 0 0012 7.5V21m0 0a2 2 0 10-2-2m2 2a2 2 0 012-2"></path>
              </svg>
            </div>
            <h3 className="text-xl sm:text-2xl font-bold mb-4">Our Vision</h3>
            <p className="text-base sm:text-lg leading-relaxed font-Poppins">
              Our vision is to become the leading name in car care by setting new industry standards through cutting-edge technology and unparalleled service. We strive to create a cleaner and brighter future for every vehicle we touch.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default MissionVisionSection;
