import React from 'react';
import { motion } from 'framer-motion';
import carImage from '../assets/UwashH.png';

const HeroSection = () => {
  return (
    <section className="bg-gradient-to-br from-green-800 via-green-600 to-green-700 text-white min-h-screen flex items-center font-Poppins">
      <div className="container mx-auto px-6 sm:px-4 lg:px-5 lg:pl-10 py-20 sm:py-12 lg:py-20 mt-16">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          
          {/* Image - Now on top for mobile */}
          <motion.div
            className="w-full lg:w-1/2 order-1 lg:order-2 mb-8 lg:mb-0 relative"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6 }}
          >
            <div className="absolute inset-0  rounded-3xl opacity-40"></div>
            <img
              src={carImage}
              alt="Car wash service"
              className=" object-cover w-full h-44 sm:h-80 lg:h-96"
            />
          </motion.div>
          
          {/* Text Content */}
          <motion.div
            className="w-full lg:w-1/2 lg:pr-12 order-2 lg:order-1"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-3 leading-tight lg:leading-tight text-center lg:text-left uppercase">
            Your Car Deserves 
              <br className="hidden sm:block" />
              <span className="text-yellow-400"> the Best Care!</span>
            </h1>
            <motion.p
              className="text-base sm:text-xl lg:text-lg mb-8 font-light lg:leading-relaxed lg:tracking-wide leading-relaxed text-center lg:text-left capitalize"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
            You know your car deserves the best, but what if we could take it a step further? At U-Drive, we don’t just clean your car—we elevate it. Discover a wash that’s more than routine, where attention to detail meets unparalleled care.
            </motion.p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 justify-center lg:justify-start">
              <motion.button
                className="bg-yellow-400 text-blue-900 px-8 py-3 rounded-full text-lg font-medium hover:bg-white transition duration-300 shadow-lg"
                whileHover={{ scale: 1.05, boxShadow: "0px 0px 15px rgba(255, 255, 0, 0.7)" }}
                whileTap={{ scale: 0.95 }}
              >
              View Our Services
              </motion.button>
             
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
