import React from 'react';
import PeopleIcon from '@mui/icons-material/People';
import BuildIcon from '@mui/icons-material/Build';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const WhyChooseUsSection = () => {
  const reasons = [
    { title: 'Experienced Team', description: 'Our team consists of experienced professionals dedicated to providing top-notch service.', icon: <PeopleIcon style={{ fontSize: 25 }} /> },
    { title: 'Quality Equipment', description: 'We use state-of-the-art equipment to ensure the best care for your vehicle.', icon: <BuildIcon style={{ fontSize: 25 }} /> },
    { title: 'Customer Satisfaction', description: 'We prioritize customer satisfaction and strive to exceed your expectations.', icon: <ThumbUpIcon style={{ fontSize: 25 }} /> },
  ];

  // Use useInView to track visibility of each reason card
  const [ref1, inView1] = useInView({ threshold: 1.0 });
  const [ref2, inView2] = useInView({ threshold: 1.0 });
  const [ref3, inView3] = useInView({ threshold: 1.0 });

  // Use useSpring to animate opacity and transform for each reason card
  const animatedProps1 = useSpring({ opacity: inView1 ? 1 : 0, transform: inView1 ? 'translateY(0)' : 'translateY(20px)', config: { tension: 300, friction: 20 } });
  const animatedProps2 = useSpring({ opacity: inView2 ? 1 : 0, transform: inView2 ? 'translateY(0)' : 'translateY(20px)', config: { tension: 300, friction: 20 } });
  const animatedProps3 = useSpring({ opacity: inView3 ? 1 : 0, transform: inView3 ? 'translateY(0)' : 'translateY(20px)', config: { tension: 300, friction: 20 } });

  return (
    <section className="py-16 bg-slate-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl sm:text-4xl md:text-4xl font-semibold mb-8 sm:mb-12 text-center font-ubuntu text-black">Why Choose Us</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <animated.div ref={ref1} style={{ ...animatedProps1, width: '100%' }}>
            <div className="flex flex-col items-center rounded-lg p-6 md:p-8 bg-white shadow-md">
              <div className="rounded-full bg-blue-600 text-white w-14 h-14 flex items-center justify-center mb-4">
                {reasons[0].icon}
              </div>
              <h3 className="text-lg sm:text-xl md:text-xl font-medium mb-3 mt-3 text-center font-ubuntu">{reasons[0].title}</h3>
              <p className="text-gray-700 text-center font-lato">{reasons[0].description}</p>
            </div>
          </animated.div>
          <animated.div ref={ref2} style={{ ...animatedProps2, width: '100%' }}>
            <div className="flex flex-col items-center rounded-lg p-6 md:p-8 bg-white shadow-md">
              <div className="rounded-full bg-blue-600 text-white w-14 h-14 flex items-center justify-center mb-4">
                {reasons[1].icon}
              </div>
              <h3 className="text-lg sm:text-xl md:text-xl font-medium mb-3 mt-3 text-center font-ubuntu">{reasons[1].title}</h3>
              <p className="text-gray-700 text-center font-lato">{reasons[1].description}</p>
            </div>
          </animated.div>
          <animated.div ref={ref3} style={{ ...animatedProps3, width: '100%' }}>
            <div className="flex flex-col items-center rounded-lg p-6 md:p-8 bg-white shadow-md">
              <div className="rounded-full bg-blue-600 text-white w-14 h-14 flex items-center justify-center mb-4">
                {reasons[2].icon}
              </div>
              <h3 className="text-lg sm:text-xl md:text-xl font-medium mb-3 mt-3 text-center font-ubuntu">{reasons[2].title}</h3>
              <p className="text-gray-700 text-center font-lato">{reasons[2].description}</p>
            </div>
          </animated.div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsSection;
