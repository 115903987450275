import React from 'react';

const ContactForm = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your form submission logic here
    console.log('Form submitted!');
  };

  return (
    <section className="py-12 font-Poppins">
      <div className="container lg:mx-10 px-2 sm:px-6 lg:px-8">
        <h2 className="text-3xl lg:text-4xl font-semibold mb-3 text-green-600 pl-5">Get in Touch</h2>
        <form onSubmit={handleSubmit} className=" rounded-lg p-8 max-w-full">
          <div className="flex flex-col lg:flex-row lg:space-x-6">
            <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
              <label htmlFor="name" className="block text-gray-700 font-medium mb-2 text-sm lg:text-base">Your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                required
              />
            </div>
            <div className="w-full lg:w-1/2">
              <label htmlFor="email" className="block text-gray-700 font-medium mb-2 text-sm lg:text-base">Your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                required
              />
            </div>
          </div>
          <div className="w-full mt-4 mb-6">
            <label htmlFor="message" className="block text-gray-700 font-medium mb-2 text-sm lg:text-base">Message</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              required
            ></textarea>
          </div>
          <div className="w-full">
            <button
              type="submit"
              className="bg-yellow-400 text-black px-6 py-4 rounded-lg hover:bg-green-500 transition duration-300 w-full"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
