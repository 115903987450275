import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white text-black p-4 mt-0">
      <div className="container mx-auto text-center lg:py-2">
        <p className='font-Poppins'>&copy; 2024 U-Wash. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
