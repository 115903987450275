import React from 'react';
import { motion } from 'framer-motion';

const Services = () => {
  const services = [
    {
      title: 'Quality Choice',
      description: 'Experience a wide range of premium car wash options from U-Wash partners. Our swift booking process ensures your car receives top-notch treatment within 24 hours, backed by our satisfaction guarantee.',
      icon: '✨'
    },
    {
      title: 'Exclusive Service',
      description: 'Our dedicated U-Drive partners are committed to automotive perfection. Indulge in our premium services that will make your vehicle shine for any occasion, from daily commutes to special events.',
      icon: '🚗'
    },
    {
      title: 'Fast and Safe',
      description: 'Book your wash in minutes and enjoy peace of mind. Our cutting-edge equipment and eco-friendly products deliver a swift, safe, and environmentally conscious car wash experience.',
      icon: '🛡️'
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        duration: 0.8
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <section className="bg-gradient-to-br ft from-green-700 via-green-600 to-green-600 text-white py-12 sm:py-16 lg:py-24 overflow-hidden relative">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className="text-2xl sm:text-3xl lg:text-4xl font-bold text-center mb-12 sm:mb-12 lg:mb-16 capitalize font-Poppins"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Elevate Your Car Care Experience
        </motion.h2>
        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 lg:gap-10"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {services.map((service, index) => (
            <motion.div 
              key={index} 
              className="relative bg-white bg-opacity-10 rounded-xl p-5 sm:p-8 backdrop-filter backdrop-blur-md transition-all duration-300 hover:bg-opacity-20 hover:shadow-xl group"
              variants={itemVariants}
            >
              <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 w-16 h-16 sm:w-20 sm:h-20 bg-gradient-to-br from-yellow-400 to-yellow-600 rounded-full flex items-center justify-center text-blue-800 text-3xl sm:text-4xl shadow-lg group-hover:scale-110 transition-transform duration-300">
                {service.icon}
              </div>
              <h3 className="text-xl sm:text-2xl font-bold mb-4 mt-8 group-hover:text-yellow-400 transition-colors duration-300">{service.title}</h3>
              <p className="text-base sm:text-lg leading-relaxed font-Poppins font-light">{service.description}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className="absolute top-1/2 left-0 w-48 h-48 bg-yellow-400 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
        <div className="absolute top-1/4 right-0 w-56 h-56 bg-blue-400 rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-2000"></div>
      </div>
    </section>
  );
};

export default Services;
