import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import L from 'leaflet';

// Create a default icon for the marker
const DefaultIcon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  shadowSize: [41, 41]
});

const LeafletMapComponent = () => {
  // Location coordinates for Bole Wollo Sefer, Addis Ababa, Ethiopia
  const position = [8.9896, 38.7671]; 

  return (
    <section className="bg-gray-300 py-12 relative">
      <div className="absolute inset-0 bg-white opacity-70 z-0"></div>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10 mt-16">

        <div className="relative h-80 sm:h-96 lg:h-[500px]">
          <MapContainer center={position} zoom={15} className="w-full h-full">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position} icon={DefaultIcon}>
              <Popup>
                Our Location in Bole Wollo Sefer, Addis Ababa, Ethiopia.
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </section>
  );
};

export default LeafletMapComponent;
