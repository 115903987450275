import React from 'react';
import { Link } from 'react-router-dom';
import VipWashImage from '../assets/Vipwash.jpg';
import BodyWashImage from '../assets/bodywash.jpg';
import FullWashImage from '../assets/herowash.jpg';
import DeluxeWashImage from '../assets/Comit.jpg';
import EcoWash from '../assets/car-image.jpg';
import QuickWashImage from '../assets/car.jpg';
import UltimateImage from '../assets/new.jpg';
import ExpressImage from '../assets/aboutWa.jpg';
import SupremeImage from '../assets/About2.jpg';

const carWashTypes = [
  { type: 'Full Wash', img: FullWashImage, description: 'Our Luxury Wash provides the best in class cleaning and detailing for your car.', price: '$ -' },
  { type: 'Body Wash', img: BodyWashImage, description: 'The Standard Wash is a comprehensive cleaning solution for your vehicle.', price: '$ - ' },
  { type: 'VIP Wash', img: VipWashImage, description: 'Economy Wash offers a budget-friendly and fantastic option with quality cleaning.', price: '$ -' },
  // Add more services for the service page
  { type: 'Deluxe Wash', img: DeluxeWashImage, description: 'A premium wash with additional features for an enhanced shine.', price: '$ -' },
  { type: 'Eco Wash', img: EcoWash, description: 'An eco-friendly wash that conserves water and uses sustainable products.', price: '$ -' },
  { type: 'Quick Wash', img: QuickWashImage, description: 'A fast and efficient wash for those on the go.', price: '$ -' },
  { type: 'Ultimate Wash', img: UltimateImage, description: 'The ultimate in car washing, including interior and exterior detailing.', price: '$ -' },
  { type: 'Express Wash', img: ExpressImage, description: 'An express option for a quick and thorough clean.', price: '$ -' },
  { type: 'Supreme Wash', img: SupremeImage, description: 'A supreme wash offering the highest level of care and attention.', price: '$ -' },
];

const SpecialCollection = ({ showAll = false, isServicesPage = false }) => {
  // Determine how many services to show based on the `showAll` prop
  const servicesToShow = showAll ? carWashTypes : carWashTypes.slice(0, 3);

  return (
    <section className={`bg-white text-gray-800 py-16 sm:py-24 ${isServicesPage ? 'lg:py-40 pt-32' : ''}`}>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end mb-8 sm:mb-12 lg:mb-16">
          <div className="max-w-2xl mb-4 sm:mb-0">
            <h2 className="text-2xl sm:text-4xl font-semibold font-Poppins text-green-600 mb-4 sm:mb-6">Exclusive Services</h2>
            <p className='text-sm sm:text-xl text-gray-600 leading-relaxed font-Poppins lg:text-lg'>
              {showAll ? 'Explore our full range of services and choose the best one for your needs.' : 'Choose from our special collection of car wash services designed to give your car the ultimate care it deserves.'}
            </p>
          </div>
          {!showAll && (
            <Link to="/services" className="text-green-500 font-semibold font-Poppins text-base sm:text-lg hover:underline transition duration-300 flex items-center">
              See all services
              <span className="ml-2">→</span>
            </Link>
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-12">
          {servicesToShow.map((service, index) => (
            <div key={index} className="bg-white rounded-2xl shadow-xl overflow-hidden transition transform hover:scale-105 hover:shadow-2xl duration-300">
              <img src={service.img} alt={service.type} className="w-full h-48 sm:h-56 lg:h-64 object-cover" />
              <div className="p-6 sm:p-8">
                <h3 className="text-lg sm:text-xl font-semibold font-Poppins text-gray-800 mb-3 sm:mb-4">{service.type}</h3>
                <p className="text-gray-600 mb-4 sm:mb-6 text-sm font-light font-Poppins lg:text-base sm:text-lg">{service.description}</p>
                <div className="flex justify-between items-center">
                  <span className="text-base sm:text-2xl font-light font-Poppins text-green-500">{service.price}</span>
                  <button className="bg-green-400 text-black font-Poppins font-light px-4 sm:px-6 py-2 sm:py-3 rounded-full text-sm sm:text-lg hover:bg-blue-100 transition duration-300 shadow-md">
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SpecialCollection;
