import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const ContactSection = () => {
  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className="relative bg-green-700 text-white py-12 md:py-20 font-Poppins">
      <div className="absolute inset-0 bg-pattern opacity-10 pointer-events-none"></div>
      <div className="container mx-auto px-4 md:px-8 relative z-10">
        <div className="flex flex-col md:flex-row justify-between items-start">
          
          {/* Office Info */}
          <motion.div
            className="w-full md:w-1/3 md:pr-8 mb-8 md:mb-0"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-xl md:text-3xl font-bold mb-4 md:mb-6 text-white font-Poppins">Address</h2>
            <p className="mb-4 text-white text-sm md:text-lg font-Poppins">044 - Peace Avenue (Addisu Asphalt) near Peacock park</p>
            <p className="mb-4 text-white text-sm md:text-lg font-Poppins">support@ethioclean.com</p>
            <p className="mb-3 text-white text-sm md:text-lg font-Poppins">+251 9 82 37 77 77</p>
            <p className="text-white text-sm md:text-lg font-Poppins">+251 9 85 37 77 77</p>
            
            {/* Social Media Icons */}
            <div className="flex space-x-8 mt-6">
              <a href="#" className="text-white hover:text-yellow-400 transition duration-300">
                <FontAwesomeIcon icon={faFacebook} size="xl" />
              </a>
              <a href="#" className="text-white hover:text-yellow-400 transition duration-300">
                <FontAwesomeIcon icon={faTwitter} size="xl" />
              </a>
              <a href="#" className="text-white hover:text-yellow-400 transition duration-300">
                <FontAwesomeIcon icon={faInstagram} size="xl" />
              </a>
              <a href="#" className="text-white hover:text-yellow-400 transition duration-300">
                <FontAwesomeIcon icon={faTiktok} size="xl" />
              </a>
            </div>
          </motion.div>

          {/* Links */}
          <motion.div
            className="w-full md:w-1/3 md:px-8 mb-8 md:mb-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <h2 className="text-xl md:text-3xl font-bold mb-4 md:mb-6 text-white font-Poppins">Pages</h2>
            <ul className="space-y-3">
              {['About', 'Services', 'Contact', 'Home'].map((item, index) => (
                <motion.li
                  key={index}
                  whileHover={{ x: 10 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <a href="#" className="text-white hover:text-yellow-400 transition duration-300 text-sm md:text-lg font-Poppins">
                    {item}
                  </a>
                </motion.li>
              ))}
            </ul>
            {/* Additional Description */}
            <p className="mt-7 font-bold text-white text-sm md:text-lg font-Poppins uppercase lg:pr-8">
              Explore our website Pages With these links
            </p>
          </motion.div>

          {/* Newsletter */}
          <motion.div
            className="w-full md:w-1/3 md:pl-8"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-xl md:text-3xl font-bold mb-4 md:mb-8 text-white font-Poppins">Newsletter</h2>
            <form
              className="flex flex-col space-y-4"
              onSubmit={(e) => {
                e.preventDefault();
                const email = e.target.elements.email.value;
                if (!email || !/\S+@\S+\.\S+/.test(email)) {
                  alert("Please enter a valid email address");
                } else {
                  alert("Thank you for subscribing!");
                }
              }}
            >
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="w-full px-4 py-3 rounded-md bg-blue-100 text-black border focus:outline-none focus:ring-2 focus:ring-yellow-400 transition duration-300"
                required
              />
              <button className="w-full bg-yellow-400 text-black px-6 py-4 rounded-md transition duration-300 hover:bg-blue-100 text-base md:text-lg font-semibold font-Poppins">
                Subscribe
              </button>
            </form>
            {/* Back to Top Button */}
            <button
              onClick={handleBackToTop}
              className="mt-12 w-full bg-yellow-400 text-black px-6 py-4 rounded-md flex items-center justify-center transition duration-300 hover:bg-white"
            >
              <FontAwesomeIcon icon={faArrowUp} size="lg" />
              <span className="ml-2">Back to Top</span>
            </button>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
