import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronDown } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const menuItems = [
    { name: 'Home', link: '/home' },
    { name: 'About', link: '/about' },
    { name: 'Services', link: '/services', submenu: ['Full Wash', 'Body Wash', 'VIP Wash'] },
    { name: 'Contact', link: '/contact' },
  ];

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-white shadow-md' : 'bg-gray-50'}`}>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <div className="flex items-center">
            <span className="lg:text-2xl text-xl font-bold text-green-600 font-Satisfy uppercase">U-Drive</span>
          </div>

          <nav className="hidden md:flex space-x-8 items-center font-Poppins">
            {menuItems.map((item) => (
              <div key={item.name} className="relative group">
                <a
                  href={item.link}
                  className="text-gray-800 hover:text-green-600 font-light transition-colors duration-300"
                  onMouseEnter={() => setActiveDropdown(item.name)}
                  onMouseLeave={() => setActiveDropdown(null)}
                >
                  {item.name}
                  {item.submenu && <ChevronDown className="inline-block ml-1" size={16} />}
                </a>
                {item.submenu && activeDropdown === item.name && (
                  <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-gray-200 ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      {item.submenu.map((subItem) => (
                        <a
                          key={subItem}
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 hover:text-gray-900"
                          role="menuitem"
                        >
                          {subItem}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <a
              href="#app"
              className="bg-yellow-400 text-black px-6 py-3 rounded-full hover:bg-blue-500 hover:text-white transition-colors duration-300 transform hover:scale-105"
            >
              Download App
            </a>
          </nav>

          <button onClick={toggleMenu} className="md:hidden text-gray-800 hover:text-blue-600 transition-colors duration-300">
            {isMenuOpen ? <X size={30} /> : <Menu size={30} color='green' />}
          </button>
        </div>

        {isMenuOpen && (
          <nav className="md:hidden py-4 bg-white text-gray-800 font-Poppins rounded-lg shadow-lg overflow-hidden">
            {menuItems.map((item) => (
              <div key={item.name}>
                <a
                  href={item.link}
                  className="block py-2 px-4 text-gray-800 hover:bg-gray-100 transition-colors duration-300"
                >
                  {item.name}
                </a>
                {item.submenu && (
                  <div className="pl-8 bg-gray-50">
                    {item.submenu.map((subItem) => (
                      <a
                        key={subItem}
                        href="#"
                        className="block py-2 px-4 text-gray-600 hover:bg-gray-100 transition-colors duration-300"
                      >
                        {subItem}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <a
              href="#app"
              className="block py-2 px-4 mt-2 bg-yellow-400 text-black text-center rounded-full hover:bg-blue-500 hover:text-white transition-colors duration-300"
            >
              Download App
            </a>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;