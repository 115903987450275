import React from 'react';
import { motion } from 'framer-motion';
import aboutImage from '../assets/AboutW.jpg';
import about2 from '../assets/bodywash.jpg';

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

const AboutSection = ({ isAboutPage }) => {
  return (
    <section className={`bg-gradient-to-b from-gray-50 to-blue-50 text-gray-800 py-16 sm:py-24 overflow-hidden font-Poppins ${isAboutPage ? 'lg:pt-40 pt-32' : ''}`}>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* First Block */}
        <motion.div 
          className="flex flex-col lg:flex-row items-center mb-8 lg:mb-20"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <motion.div 
            className="w-full lg:w-1/2 mb-10 lg:mb-0 relative"
            variants={fadeIn}
          >
            <img src={aboutImage} alt="About U-Wash" className="rounded-2xl shadow-xl object-cover w-full h-64 sm:h-80 lg:h-[400px] transition-transform transform hover:scale-105" />
            <div className="absolute bottom-4 right-4 bg-yellow-400 text-black p-4 rounded-lg shadow-lg transform rotate-3 hidden sm:block">
              <p className="font-bold text-lg">10+ Years of Excellence</p>
            </div>
          </motion.div>
          <motion.div 
            className="w-full lg:w-1/2 lg:pl-16"
            variants={fadeIn}
          >
            <h2 className="text-3xl sm:text-4xl font-medium mb-4 sm:mb-6 text-green-600 capitalize">About U-Drive</h2>
            <p className="text-gray-700 mb-6 sm:mb-8 text-base font-light lg:text-lg leading-loose capitalize lg:leading-loose">
      our mission is simple: to provide top-tier car wash and detailing services that leave every vehicle spotless and every customer satisfied. From our skilled team to our state-of-the-art equipment, we’re dedicated to delivering results that speak for themselves.
            </p>
            <ul className="space-y-4 mb-8">
              {['Advanced Detailing Techniques', 'Quick Turnaround Time', '100% satisfaction guarantee', 'Trusted by Car Enthusiasts'].map((item, index) => (
                <motion.li 
                  key={index} 
                  className="flex items-center text-lg font-medium text-gray-700"
                  variants={fadeIn}
                >
                  <svg className="w-6 h-6 mr-3 text-yellow-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  {item}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </motion.div>

        {/* Second Block */}
        <motion.div 
          className="flex flex-col lg:flex-row-reverse items-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          transition={{ staggerChildren: 0.2 }}
        >
          <motion.div 
            className="w-full lg:w-1/2 mb-10 lg:mb-0 relative"
            variants={fadeIn}
          >
            <img src={about2} alt="Our Commitment" className="rounded-2xl shadow-xl object-cover w-full h-64 sm:h-80 lg:h-[400px] transition-transform transform hover:scale-105" />
            <div className="absolute top-4 left-4 bg-green-600 text-white p-4 rounded-lg shadow-lg transform -rotate-3 hidden sm:block">
              <p className="font-bold text-lg">Eco-Friendly Innovation</p>
            </div>
          </motion.div>
          <motion.div 
            className="w-full lg:w-1/2 lg:pr-12 "
            variants={fadeIn}
          >
            <h2 className="text-3xl sm:text-4xl font-medium mb-4 sm:mb-6 text-green-600 capitalize">Our Green Promise</h2>
            <p className="text-gray-700 mb-6 sm:mb-8 text-base font-light lg:text-lg leading-loose lg:leading-loose capitalize">
            We’re committed to eco-friendly practices in everything we do. Our advanced water-saving systems, biodegradable cleaning products, and energy-efficient operations ensure that your car gets the best treatment while minimizing our environmental impact.
            </p>
            <ul className="space-y-4 mb-8">
              {['90% water reclamation system', 'Biodegradable cleaning solutions', 'Minimal Environmental Footprint', 'Energy-Efficient Operations'].map((item, index) => (
                <motion.li 
                  key={index} 
                  className="flex items-center text-lg font-medium text-gray-700"
                  variants={fadeIn}
                >
                  <svg className="w-6 h-6 mr-3 text-yellow-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  {item}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutSection;
